import React, { useRef, useState } from "react"
import { connect } from "react-redux"
import { Layout, FlexRow } from "@components/layout"

import { JSONLD, Product as DataProduct } from "react-structured-data"

import { SimpleProduct, VariableProduct } from "@components/ecommerce"

import { cartActions } from "@stores"

const Product = ({ pageContext, addToCart, store }) => {
  const { product, page } = pageContext
  const { languages } = product
  const { translations } = languages
  const translationUrls = {
    pl:`/shop/products/${translations}/`,
    en:`/shop/products/${translations}/`
  }

  return (
    <Layout {...product} translationUrl={translationUrls}>
      <JSONLD>
        <DataProduct
          name={product.name}
          description={product.description}
          aggregateRating={product.reviews.averageRating}
          slogan={product.shortDescription}
        ></DataProduct>
      </JSONLD>
      <SimpleProduct
        product={product}
        addToCart={addToCart}
        cart={store.items}
      />
    </Layout>
  )
}

const mapStateToProps = (state) => ({ store: state.Cart })

const mapActionToDispatch = { ...cartActions }

export default connect(mapStateToProps, mapActionToDispatch)(Product)
